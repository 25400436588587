// @ts-ignore
import debounce from 'lodash/debounce';
import {Backdrop, Box, CircularProgress, Pagination} from "@/node_modules/@mui/material/index";
import React, {useState, useRef, useEffect, useCallback} from "react";
import {callApiGetGarments, callApiGetColors} from "../common/api";
import OutlinedButton from "@/app/components/OutlinedButton";
import Male from '@mui/icons-material/Male';
import Female from '@mui/icons-material/Female';
import Dialog from "@mui/material/Dialog";
import {useTheme, Zoom, Fade, Slide} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {ProductInfo} from "@/app/components/ProductInfo";
import {ProductCard} from "@/app/components/ProductCard";

const productCardWidth = 225;
const columnGap = 40;

export default function VirtualGridProducts({
                                              handleTryOn,
                                              goToGallery,
                                              tryOnTaskResults,
                                              selectionLimit,
                                              handleToggleInWishlist,
                                              wishList,
                                              currentPage,
                                              setCurrentPage,
                                              totalPages,
                                              products,
                                            }: any) {
  const [loading, setLoading] = useState(false);
  const [productsSelected, setButtonStates]: any = useState({})
  const [productInfoDetails, setProductInfoDetails] = useState<any>(null);
  const {t} = useTranslation();

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  function doClick(data: any) {
    const updatedState = {
      ...productsSelected,
    };

    if (productsSelected[data.prod._id]) {
      delete updatedState[data.prod._id];
    } else {
      updatedState[data.prod._id] = data.prod;
    }
    setButtonStates(updatedState);
  }

  function handleGridTryOn() {
    handleTryOn(productsSelected);
    setButtonStates({});
    setLoading(true);
  }

  const handleInfo = (product: any) => {
    setProductInfoDetails(product);
  }

  const selectedLength = Object.values(productsSelected).filter(Boolean).length;

  const checkIsInWishlist = (product: any) => {
    return !!wishList.find((el: any) => el._id === product._id)
  }

  const [openFilters, setOpenFilters] = useState(false);

  return (
    <Box sx={{
      position: "absolute",
      width: `${productCardWidth * 3 + 2 * columnGap}px`,
      left: '100px',
      top: '200px',
      display: "flex",
      flexDirection: "column",
    }}>

      <Dialog PaperProps={{
        sx: {
          color: '#fff',
          backgroundColor: 'rgba(19, 19, 19, 0.95)'
        },
      }} maxWidth="lg" open={openFilters} onClose={() => setOpenFilters(false)}>

        <Box sx={{
          padding: '60px',
        }}>
          <OutlinedButton color="secondary">
            <Male
              sx={{
                width: '64px', height: '64px'

              }}/>
          </OutlinedButton>

          <OutlinedButton>
            <Female
              sx={{
                width: '64px', height: '64px'

              }}/>
          </OutlinedButton>
        </Box>
      </Dialog>


      <Dialog PaperProps={{
        sx: {
          width: '100%',
          maxHeight: 'unset !important',
          color: '#fff',
          backgroundColor: 'rgba(19, 19, 19, 0.95)'
        },
      }} maxWidth="lg" open={!!productInfoDetails} onClose={() => setProductInfoDetails(null)}>
        {!!productInfoDetails && (
          <ProductInfo
            productInfoDetails={productInfoDetails}
            checkIsProductAddedToWishlist={(product: any) => checkIsInWishlist(product)}
            handleToggleInWishlist={(product: any) => handleToggleInWishlist(product)}
            goToGallery={goToGallery}
            tryOnTaskResults={tryOnTaskResults}
            handleTryOn={(product: any) => handleTryOn({[product._id]: product}, true)}
          />
        )}
      </Dialog>

      <Box sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00FF0000"
      }}>

        <Box sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: `${columnGap}px`,
          rowGap: '40px'
        }}>
          {products?.map((product: any, index: number) => (
              <Fade in key={product._id}>
                <Box key={product._id} sx={{width: `${productCardWidth}px`}}>
                  <ProductCard
                    isSelectable
                    handleToggleInWishlist={handleToggleInWishlist}
                    onInfo={handleInfo}
                    product={product}
                    onClickSelect={doClick}
                    buttonStates={productsSelected}
                    isAddedToWishlist={checkIsInWishlist(product)}
                  />
                </Box>
              </Fade>

            )
          )}

          {!!products?.length && (
            <>
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    fontSize: '2.2rem',
                    '.MuiPaginationItem-ellipsis': {color: '#fff', fontSize: '2.2rem'},
                    '.Mui-selected': {
                      color: ({palette}) => `${palette.secondary.main} !important`,
                      fontWeight: 'bolder !important'
                    },
                    li: {mx: 1},
                    svg: {width: '46px', height: '46px',}
                  }}
                />
              </Box>
              <OutlinedButton
                disabled={!selectedLength || selectedLength > selectionLimit}
                onClick={handleGridTryOn}
                sx={{width: '100%'}}
              >
                {loading && <CircularProgress sx={{color: '#fff', position: 'absolute'}}/>}
                <Box sx={{visibility: loading ? 'hidden' : 'visible'}}>
                  {t('startTryOn')} {selectedLength}/{selectionLimit}
                </Box>

              </OutlinedButton>
            </>
          )}

        </Box>

        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>

      </Box>
    </Box>
  )
};
