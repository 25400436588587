import axios from 'axios';
import {API_URL} from '../common/constants';
import {LS_LNG_KEY} from "@/app/i18n";

export const API_SUCCESS = 'success';
export const API_ERROR = 'error';

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('company');
  localStorage.removeItem(LS_LNG_KEY);
};

export const callApiGetGarments = async (page: number = 1, pageSize: number = 10, gender: string, item_type: string = '', color: string = '', model: string = '') => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/garments/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        page,
        gender: gender,
        item_type,
        color,
        page_size: pageSize,
        model
      }
    });
    return response.data.data;
  } catch (error: any) {
    console.error('Error uploading video:', error);
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
  return {};
}

export const callApiGetColors = async (gender = '') => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/garments/color`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        gender
      }
    });
    return response.data.data;
  } catch (error: any) {
    console.error('Error uploading video:', error);
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
  return {};
}

export const callApiGetItemTypes = async (gender = '') => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/garments/item_type`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        gender
      }
    });
    return response.data.data;
  } catch (error: any) {
    console.error('Error uploading video:', error);
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
  return {};
}

export const callApiDeleteGarment = async (id: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.delete(`${API_URL}/garments/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      console.log('success');
    } else {
      console.error('Failed to delete garment');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

export const callApiCreateGarment = async (formData: any) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/garments/`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      console.log('success');
    } else {
      console.error('Failed to upload garment');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

export const callApiEditGarment = async (id: string, formData: any) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.put(`${API_URL}/garments/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    if (response.status === 200) {
      console.log('success');
    } else {
      console.error('Failed to edit garment');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

export const callApiSubmitFrameSelectionTask = async (recordedChunks: any) => {
  const blob = new Blob(recordedChunks.current, {type: 'video/webm'});
  let numImages = '5';
  let resolution = '720p';
  const formData = new FormData();
  formData.append('subject_video_file', blob, 'recording.webm');
  formData.append('frame_number', numImages.toString());
  formData.append('resolution', resolution);
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/frame-selection/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
}

export const callApiGenerateBodyMesh = async (photo: File) => {
  const formData = new FormData();
  formData.append('subject_photo', photo);
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/size-me/generate-body-mesh`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
}

export const callApiGetFrameSelectionTaskStatus = async (taskId: string) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/frame-selection/status/${taskId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
}

export const callApiSubmitVirtualTryOnTask = async (photo: File, garmentUrl: string, category: string, shirtType?: string) => {
  let selectedModel = localStorage.getItem('selectedModelValue');
  if (selectedModel === null) {
    selectedModel = 'm1';
  }
  const formData = new FormData();
  formData.append('subject_photo', photo);
  formData.append('category', category);
  formData.append('garment_url', garmentUrl);
  shirtType && formData.append('shirt_type', shirtType);
  formData.append('model', selectedModel);
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/virtual-try-on/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
}

export const callApiGetVirtualTryOnTaskStatus = async (taskId: string) => {
  let selectedModel = localStorage.getItem('selectedModelValue');
  if (selectedModel === null) {
    selectedModel = 'm1';
  }
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/virtual-try-on/status/${taskId}/${selectedModel}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      logout();
    }
  }
}