import {useEffect, useRef, useState} from "react";
import TakePhotoCountDown from "./TakePhotoCountDown";
import VirtualCamera from "./VirtualCamera";
import StoreLayout from "@/app/layouts/StoreLayout";
import {logout} from "@/app/common/api";
import {useTranslation} from "react-i18next";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

// Define an enum to represent the steps
enum VirtualSteps {
  StepCountDown,
  StepTakePictures,
  StepEditPictures
}

const queryClient = new QueryClient()


export default function VirtualMainView() {
  const [virtualMainStep, setVirtualMainStep] = useState<VirtualSteps>(VirtualSteps.StepCountDown);
  const [incLogoutHiddenCounter, setIncLogoutHiddenCounter] = useState(0);
  const shouldForceUnload = useRef(false);
  const [subjectImages, setImageFrames] = useState<string[]>([])
  const {t} = useTranslation();

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (shouldForceUnload.current || process.env.NODE_ENV === 'development') return;

      e.preventDefault();
    }
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [])

  useEffect(() => {
    // detect if the user is idle and go to first step after 10 minutes of inactivity
    const startIdleTimeout = () => {
      return setTimeout(() => {
        handleRetakePicture();
      }, 10 * 60 * 1000);
    }
    let timeoutId = startIdleTimeout();
    const resetIdleTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = startIdleTimeout();
    }
    document.addEventListener('click', resetIdleTimeout)

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('click', resetIdleTimeout)
    }
  }, [])

  useEffect(() => {
    const resetCounterTimeout = setTimeout(() => {
      setIncLogoutHiddenCounter(0)
    }, 1000 * 20)

    if (incLogoutHiddenCounter >= 10) {
      shouldForceUnload.current = true;
      logout()
      window.location.reload()
    }

    return () => {
      clearTimeout(resetCounterTimeout)
    }
  }, [incLogoutHiddenCounter])

  const handleHiddenIncCounter = () => {
    setIncLogoutHiddenCounter(prev => prev + 1)
  }

  function handleCloseCountDown(base64SubjectPhotos: string[]) {
    setImageFrames(base64SubjectPhotos)
    setVirtualMainStep(VirtualSteps.StepTakePictures);
  }

  function handleVirtualCameraEventFinish() {
    setVirtualMainStep(VirtualSteps.StepEditPictures);
  }

  function handleRetakePicture() {
    setImageFrames([])
    setVirtualMainStep(VirtualSteps.StepCountDown);
  }

  const goHome = () => {
    if (virtualMainStep == VirtualSteps.StepCountDown) return;

    const answer = window.confirm(t('reshotCacheClearingWarning'));
    if (!answer) return;

    handleRetakePicture()
  }

  return (
    <QueryClientProvider client={queryClient}>
      <StoreLayout
        onLogoClick={goHome}
        hasLanguageMenu={virtualMainStep == VirtualSteps.StepCountDown}
        showTermsAndConditions={virtualMainStep == VirtualSteps.StepCountDown}
        onHiddenBoxClick={virtualMainStep == VirtualSteps.StepCountDown ? handleHiddenIncCounter : undefined}
      >
        {virtualMainStep == VirtualSteps.StepCountDown &&
					<TakePhotoCountDown countDownOpen={virtualMainStep === 0} handleSubjectPhotos={handleCloseCountDown}/>
        }
        {virtualMainStep >= VirtualSteps.StepTakePictures && (
          <VirtualCamera
            subjectImages={subjectImages}
            handleTakePictureFinish={handleVirtualCameraEventFinish}
            handleRetakePicture={handleRetakePicture}
            goHome={goHome}
          />
        )}
      </StoreLayout>
    </QueryClientProvider>

  )
}