import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useAuth} from "@/app/context/AuthContext";
import {useQuery} from "@tanstack/react-query";
import {callApiGetGarments} from "@/app/common/api";
import {Box, Fade} from "@mui/material";
import OutlinedButton from "@/app/components/OutlinedButton";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
// @ts-ignore
import uniqBy from 'lodash/uniqBy';
import CircularProgress from "@mui/material/CircularProgress";

const couponName = 'GLG-FREE';

export const ProductInfo = ({
                              hidePrimaryAction = () => false,
                              productInfoDetails,
                              checkIsProductAddedToWishlist,
                              handleToggleInWishlist,
                              tryOnTaskResults,
                              goToGallery,
                              handleTryOn,
                            }: any) => {
  const {hasCoupon} = useAuth();
  const {t} = useTranslation();
  const [previewImageIndex, setPreviewImageIndex] = useState(0);



  const [productColorVariationId, setProductColorVariationId] = useState(productInfoDetails._id);
  const productModelId = productInfoDetails?.garments?.[0]?.model;

  const {isFetching, isLoading, data = [productInfoDetails]} = useQuery({
    enabled: !!productModelId,
    queryKey: ['productModelData', {model: productModelId}],
    queryFn: ({
                queryKey: [_, {
                  // @ts-ignore
                  model
                }]
              }) => callApiGetGarments(1, 99, '', '', '', model),
    select: (data) => data.items,
  })

  // this contains the data of the currently selected product color variation
  const currentlySelectedProduct = data.find((item: any) => item._id === productColorVariationId)

  const itemInGallery = tryOnTaskResults.find((el: any) => el.product.datz === currentlySelectedProduct._id);
  const isAlreadyInGallery = !!itemInGallery;
  const isProcessing = ['PROGRESS', "UNKNOWN"].includes(itemInGallery?.state)

  const productInfo = currentlySelectedProduct?.garments[0] ?? {}
  const {name, item_type, color, description, product_url_qr} = productInfo;

  const uniqueByImageURL = uniqBy(currentlySelectedProduct?.garments ?? [], 'image_url');
  const currentProductImage = uniqueByImageURL[previewImageIndex]?.image_url;

  const colorVariants = data.map((item: any) => ({
    colorHexList: item.garments[0]?.color_hex ?? [],
    _id: item._id
  })).filter((item: any) => item.colorHexList.length)

  const checkIsColorVariationSelected = (_id: string) => {
    return productColorVariationId === _id
  }

  const changeSelectedColorVariation = (_id: string) => {
    !!_id && setProductColorVariationId(_id)
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', px: '60px', pb: '120px', pt: '80px', position: 'relative'}}>
      <Box sx={{display: 'flex', gap: '30px'}}>
        {/*info*/}
        <Box className={'dir'} sx={{
          display: 'flex',
          flexDirection: 'column', flex: 1,
        }}>
          <Box sx={{fontWeight: 900, fontSize: '2.2rem'}}>
            {name}
          </Box>

          <Box sx={{width: '300px', my: '30px', height: '2px', backgroundColor: '#fff'}}/>

          <Box sx={{position: 'relative'}}>
            <Box
              sx={{fontSize: '2rem', fontWeight: 900, marginBottom: '20px'}}>{t('productItemType', {item_type})}</Box>

            <Box sx={{fontSize: '2rem', fontWeight: 900, marginBottom: '20px'}}>{t('productItemColor', {color})}</Box>


            <Box className='dir' sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              {colorVariants.map(({colorHexList, _id}: any) => (
                <Box key={_id} onClick={() => changeSelectedColorVariation(_id)} sx={{
                  transition: '0.2s',
                  border: (theme: any) => checkIsColorVariationSelected(_id) && !isLoading ? `6px solid ${theme.palette.secondary.main} !important` : `3px solid rgba(255,255,255,0.3) !important`,
                  transform: checkIsColorVariationSelected(_id) ? 'scale(1.2,1.2)' :'none',
                  padding: '4px',
                  cursor: 'pointer',
                  margin: '10px 14px',
                  width:'72px',
                  height: '72px',
                  borderRadius: '4px',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'row',
                  flexShrink: 0,
                }}>
                  {colorHexList.map((color: string) => <Box key={color} sx={{
                      flex: 1,
                      flexShrink: 0,
                      backgroundColor: color
                    }}
                    />
                  )}
                </Box>
              ))}

              <Fade in={isFetching}>
                <CircularProgress
                  sx={{
                    margin: '0 10px',
                    color: '#fff',
                  }}
                  size={64}
                />
              </Fade>
            </Box>
          </Box>

          {/*info and actions*/}
          <Box sx={{mt: '152px'}}>
            {/*actions*/}
            <Box sx={{display: 'flex', mb: '40px', position: 'relative'}}>

              {!hidePrimaryAction(currentlySelectedProduct) && (
                <>
                  {isAlreadyInGallery && <OutlinedButton onClick={goToGallery} disabled={isProcessing}
																												 sx={{
                                                           marginInlineEnd: '20px !important',
                                                           flex: 1,
                                                           minWidth: '340px'
                                                         }}>
                    {isProcessing ? `${itemInGallery.progress}%` : t('seeInWardrobe')}
									</OutlinedButton>
                  }

                  {!isAlreadyInGallery && <OutlinedButton onClick={() => handleTryOn(currentlySelectedProduct)}
																													sx={{
                                                            marginInlineEnd: '20px !important',
                                                            flex: 1,
                                                            minWidth: '340px'
                                                          }}>
                    {t('startTryOn')}
									</OutlinedButton>
                  }
                </>
              )}

              <OutlinedButton
                sx={{
                  '&.MuiButton-outlined': {
                    padding: '20px !important',
                  },
                  svg: {
                    color: theme => `${checkIsProductAddedToWishlist(currentlySelectedProduct) ? theme.palette.secondary.main : '#fff'} !important`,
                  }
                }}
                onClick={() => handleToggleInWishlist(currentlySelectedProduct)}
              >
                {checkIsProductAddedToWishlist(currentlySelectedProduct) ? <Favorite sx={{width: '52px', height: '52px'}}/> :
                  <FavoriteBorder sx={{width: '52px', height: '52px'}}/>}
              </OutlinedButton>
            </Box>

            <Box sx={{fontSize: '2.2rem', fontWeight: 900, mb: '20px'}}>
              {t('description')}
            </Box>

            <Box sx={{fontSize: '2rem', fontWeight: 900}}>
              {description}
            </Box>
          </Box>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>

          {/*images*/}
          <Box sx={{display: 'flex', gap: '20px', maxHeight: '390px', overflow: 'hidden',}}>
            {/*main*/}
            <Box sx={{width: '260px'}}>
              <img src={currentProductImage} alt={name}/>
            </Box>
            {/*little*/}
            <Box
              sx={{display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'scroll', scrollbarWidth: 'none'}}>
              {uniqueByImageURL.map(({image_url}: any, index: number) => (
                <Box
                  key={image_url}
                  sx={{
                    width: '64px',
                    borderRadius: '6px',
                    border: theme => `3px solid ${previewImageIndex === index ? theme.palette.secondary.main : 'transparent'} !important`,
                  }}
                  onClick={() => setPreviewImageIndex(index)}
                >
                  <img src={image_url} alt={name} style={{
                    borderRadius: '4px',
                  }}/>
                </Box>
              ))}
            </Box>

          </Box>

          <Box sx={{
            marginTop: '32px',
            img: {
              width: '260px !important',
            }
          }}>
            {/*<Box sx={{position: 'absolute', width: '250px', top: '540px', right: '80px'}}>*/}
            <img src={product_url_qr} alt="qr url"/>


          </Box>
        </Box>

      </Box>
      {!!hasCoupon &&
        (
          <>
            <Box sx={{
              width: '100%',
              height: '2px',
              backgroundColor: '#fff',
              mx: 'auto',
              my: '40px'
            }}/>
            <Box sx={{direction: 'rtl', textAlign: 'center', fontSize: '2.2rem',}}>
              <Box>למשלוח חינם הזינו את קוד הקופון: <br/><span
                style={{fontWeight: 'bold', textDecoration: 'underline'}}>{couponName}</span></Box>

            </Box>
          </>
        )
      }

    </Box>
  )
}
