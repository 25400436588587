import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Fade, Zoom} from "@mui/material";
import {ProductCard} from "@/app/components/ProductCard";
import Dialog from "@mui/material/Dialog";
import {ProductInfo} from "@/app/components/ProductInfo";

// @ts-ignore
import xorBy from 'lodash/xorBy';

export const WishList = ({
                           wishList,
                           handleTryOn,
                           handleUpdateWishlist,
                           tryOnTaskResults,
                           goToGallery,
                           closeWishlist
                         }: any) => {
  const productCardWidth = 225;
  const [tempWishList, setTempWishList] = useState([...wishList])
  const [productInfoDetails, setProductInfoDetails] = useState<any>(null);
  const {t} = useTranslation();

  const checkIsInTempWishList = (product: any) => {
    return tempWishList.some((item) => item._id === product._id)
  }


  const handleToggleInTempWishlist = (product: any) => {
    setTempWishList((prev) => xorBy(prev, [product], '_id'))
  }

  const handleInfo = (product: any) => {
    setProductInfoDetails(product);
  }

  useEffect(() => {
    return () => {
      // update on unmount so that it does not jump when user interacts with the grid
      setTempWishList(prev => {
        handleUpdateWishlist(prev)
        return prev
      })
    }
  }, [])

  return (
    <Box
      sx={{
        display: "flex", position: 'relative',
        height: '100vh', width: '100vw',
      }}
    >
      <Zoom in>
        <Box onClick={closeWishlist}
             sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: "column",
               color: '#fff',
               position: 'absolute',
               top: '56px',
               left: '0',
               zIndex: '99999',
               width: '200px',
             }}
        >
          <img
            src='https://storage.gonilabs.com/assets/flow_return.png'
            width={64}
            height={64}
            alt="Go back"
          />
        </Box>
      </Zoom>

      <Box sx={{fontSize: '3rem', textAlign: 'center', width: '100%', top: '200px', position: 'absolute'}}>
        {!tempWishList.length && t('noProductsInWishlist')}
      </Box>


      <Box sx={{
        height: '80vh',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        position: 'absolute',
        width: '755px',
        left: '100px',
        top: '200px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
        }}>

          {tempWishList.map((product: any, index: number) => (
            <Fade
              in
              key={product._id}
              style={{transitionDelay: `${index * 40}ms`}}
            >

              <Box key={product._id} sx={{width: `${productCardWidth}px`}}>
                <ProductCard
                  product={product}
                  isAddedToWishlist={checkIsInTempWishList(product)}
                  isSelectable={false}
                  buttonStates={{}}
                  handleToggleInWishlist={() => handleToggleInTempWishlist(product)}
                  onInfo={handleInfo}
                />
              </Box>
            </Fade>
          ))}

        </Box>

        <Dialog PaperProps={{
          sx: {
            width: "100%",
            color: '#fff',
            backgroundColor: 'rgba(19, 19, 19, 0.95)'
          },
        }} maxWidth="lg" open={!!productInfoDetails} onClose={() => setProductInfoDetails(null)}>
          {!!productInfoDetails && (
            <ProductInfo
              productInfoDetails={productInfoDetails}
              checkIsProductAddedToWishlist={(product: any) => checkIsInTempWishList(product)}
              handleToggleInWishlist={(product: any) => handleToggleInTempWishlist(product)}
              tryOnTaskResults={tryOnTaskResults}
              goToGallery={goToGallery}
              handleTryOn={(product: any) => handleTryOn({[product._id]: product}, true)}
            />
          )}
        </Dialog>

      </Box>
    </Box>
  )
}