import React, {useState} from "react";
import {Box, useTheme} from "@mui/material";
import LargeCheckbox from "@/app/components/LargeCheckbox";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";

export const ProductCard: React.FC<{
  product: any,
  // isAlreadyInGallery: boolean,
  onClickSelect?: any,
  onInfo: any,
  handleToggleInWishlist: any,
  isAddedToWishlist: boolean,
  buttonStates: any,
  isSelectable?: boolean
}> = ({
        // isAlreadyInGallery,
        product,
        onClickSelect,
        buttonStates,
        onInfo,
        isAddedToWishlist,
        handleToggleInWishlist,
        isSelectable,
      }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const theme = useTheme();
  const availableColors = product?.garments[0]?.color_hex ?? [];
  const colorName = product?.garments[0]?.color ?? '';

  return (
    <Box
      sx={{
        position: "relative",
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      }}
    >
      {product != undefined &&
				<Box
					onClick={() => {
            isSelectable ? onClickSelect({option: 0, prod: product}) : onInfo(product);
          }}
					sx={{
            transition: '0.2s',
            position: 'relative',
            borderRadius: '4px',
            transform: buttonStates[product._id] && imageLoaded ? 'translate(10px, -10px)' : 'none',

            '&:before': {
              transition: '0.2s',
              zIndex: -9999,
              content: '""',
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundColor: buttonStates[product._id] && imageLoaded ? theme.palette.secondary.main : '#fff',
              position: 'absolute',
              transform: buttonStates[product._id] && imageLoaded ? 'translate(-20px, 20px)' : 'none',
              borderRadius: '4px',
            }
          }}>
					<img
						onLoad={() => setImageLoaded(true)}
						style={{
              objectFit: 'cover',
              borderRadius: '4px',
              height: '337px',
              transition: '0.2s',
              boxShadow: buttonStates[product._id] && imageLoaded ? '12px 12px 12px 0px rgba(0, 0, 0, 0.3)' : 'none',
            }}
						src={product.garments[0].image_url}
						alt={product.garments[0].name}
					/>

          {!!product && <Box onClick={e => {
            e.stopPropagation();
          }} sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            zIndex: 1000
          }}>
            {isSelectable && <LargeCheckbox
							size="medium"
							checked={!!buttonStates[product._id]}
							onChange={(e) => {
                e.stopPropagation();
                isSelectable && onClickSelect({option: 0, prod: product});
              }}
						/>}

						<IconButton
							onClick={() => onInfo(product)}
							sx={{
                padding: '9px !important',
                color: '#000 !important',
                svg: {
                  width: '50px',
                  height: '50px',
                }
              }}>

							<InfoOutlined/>
						</IconButton>

						<IconButton
							onClick={() => handleToggleInWishlist(product)}
							sx={{
                padding: '9px !important',
                color: `${isAddedToWishlist ? theme.palette.secondary.main : '#000'} !important`,
                svg: {
                  width: '50px',
                  height: '50px',
                }
              }}>

              {isAddedToWishlist ? <Favorite/> : <FavoriteBorder/>}
						</IconButton>
					</Box>
          }
				</Box>
      }


      {product != undefined &&
				<Box style={{display: 'flex', flexDirection: 'column', margin: 0, padding: 0}}>
					<Box className={'dir'} sx={{
            display: "inline",
            marginTop: '8px',
            padding: 0,
            fontSize: '1.8rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }} onClick={() => onInfo(product)}>
            {product.garments[0].name}
					</Box>
					<Box onClick={() => onInfo(product)}
							 sx={{display: "flex", flexDirection: "row", alignItems: 'center', height: '40px'}}>
						<Box
							sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid rgba(255,255,255,0.3) !important`,
                width: '26px',
                height: '26px',
                marginLeft: '8px',
                borderRadius: '6px',
              }}
						>

							<Box sx={{
                width: '22px',
                height: '22px',
                borderRadius: '4px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                flexShrink: 0,
              }}>
                {availableColors.map((color: string) => <Box key={color} sx={{
                    flex: 1, flexShrink: 0,
                    backgroundColor: color
                  }}/>
                )}
							</Box>
						</Box>

						<Box sx={{marginLeft: '12px', fontSize: '1.6rem'}}>{colorName}</Box>
					</Box>
				</Box>
      }
    </Box>
  );
}

